import React from 'react';
import { Car } from '../app/helpers/icons';
import Icon from './global/Icon';
import StyledSearchViewMore from './SearchViewMore.style';

const SearchViewMore = ({ onClick, children, ...p }) => {
  return (
    <StyledSearchViewMore {...p} className="view-more" onClick={onClick}>
      <Icon className="car" {...Car} />
      {children}
      <Icon
        className="chevron"
        width={8}
        height={13}
        d="M5.17217 6.99999L0.222168 2.04999L1.63617 0.635986L8.00017 6.99999L1.63617 13.364L0.222168 11.95L5.17217 6.99999Z"
      />
    </StyledSearchViewMore>
  );
};
export default SearchViewMore;
