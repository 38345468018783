import { css } from '@emotion/react';
import styled from '@emotion/styled';

export default styled.header`
  ${({ isStatic }) =>
    !isStatic &&
    css`
      transition: transform 350ms ease-in-out;
      transform: translateY(
        calc(var(--header-menu-offset) + var(--top-bar-offset))
      );
    `}

  .bar {
    ${({ theme }) => theme.layout.container};
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme, background }) => theme.colors.white};
    border-bottom: solid thin ${({ theme }) => theme.colors.quartz};
    height: var(--header-menu-height);
  }
  picture {
    display: block;
  }
  img {
    height: 60px;
    width: auto;
    margin-left: -12px;
    ${({ theme }) => theme.media.desktopSmall} {
      height: 80px;
      width: auto;
      margin-left: -15px;
    }
  }

  .menu-btn {
    cursor: pointer;
    fill: ${({ theme }) => theme.colors.bahamaBlue};
  }
  .desktop-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-self: stretch;
    gap: 15px;
    ${({ theme }) => theme.media.desktop} {
      gap: 30px;
    }
  }
`;
