import styled from '@emotion/styled';

export default styled.nav`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.aliceBlue};
  color: ${({ theme }) => theme.colors.bahamaBlue};
  transition: transform 300ms ease-in-out;
  overflow: scroll;
  transform: translateX(100%);

  body.menu-open & {
    transform: translateX(0);
  }

  & > header {
    margin-top:40px;
    border-top: solid thin ${({ theme }) => theme.colors.quartz};
  }

  & > ul {
    margin: 0;
    padding: 0;
    // body:not(.top-bar-hidden) &::before {
    //   display: block;
    //   content: '';
    //   width: 100%;
    //   top: 0;
    //   height: 40px;
    //   border-bottom: solid thin ${({ theme }) => theme.colors.quartz};
    // }
  }
`;
