import styled from '@emotion/styled';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > .result {
    border: solid thin ${({ theme }) => theme.colors.quartz};
    display: block;
    padding: 15px;
    border-radius: 4px;

    transition: border-color 0.3s ease-in-out;
    ${({ theme }) => theme.media.hover} {
      &:hover {
        border-color: ${({ theme }) => theme.colors.bahamaBlue};
      }
    }

    .page-type {
      ${({ theme }) => theme.typography.controlStrongSmaller};
      background: ${({ theme }) => theme.colors.aliceBlue};
      color: ${({ theme }) => theme.colors.bahamaBlue};
      border-radius: 4px;
      display: inline-block;
      padding: 8px;
      margin-bottom: 10px;
    }

    .title {
      ${({ theme }) => theme.typography.heading5};
      color: ${({ theme }) => theme.colors.bahamaBlue};
      .hs-search-highlight {
        color: ${({ theme }) => theme.colors.pacificBlue};
      }
    }
  }
`;
