import styled from '@emotion/styled';
import { rgba } from 'polished';

export default styled.div`
  display: flex;
  flex-direction: column;
  & > .input {
    transform: translateY(-100%);
    transition: transform 250ms ease-in-out;
    &.open {
      transform: translateY(0);
    }
    input {
      height: 60px;
      padding: 16.5px;
      border-radius: 0;
      border-left: none;
      border-right: none;
      border-top: solid 1px ${({ theme }) => theme.colors.bahamaBlue};
      border-bottom: solid 1px ${({ theme }) => theme.colors.bahamaBlue};
    }
  }
`;
