import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Search } from '../app/helpers/icons';
import Fixed from './global/Fixed';
import Icon from './global/Icon';
import TextField from './global/TextField';
import StyledMenuSearchBar from './MenuSearchBar.style';
import MenuSearchLayer from './MenuSearchLayer';

const MenuSearchBar = ({ menuWidth, ...p }) => {
  return (
    <StyledMenuSearchBar {...p}>
      <MenuSearchLayer menuWidth={menuWidth} />
      <div className="content"></div>
    </StyledMenuSearchBar>
  );
};
export default MenuSearchBar;
