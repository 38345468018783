import styled from '@emotion/styled';
import queryString from 'query-string';
import React from 'react';
import { adListing } from '../app/helpers/url';
import { usePageSearch } from '../app/providers/pageSearchProvider';
import StyledMenuSearchPageHits from './MenuSearchPageHits.style';
import SearchViewMore from './SearchViewMore';

const Hit = ({ page }) => {
  const pageType =
    {
      SITE_PAGE: 'Pagina',
      LISTING_PAGE: 'Pagina',
      LANDING_PAGE: 'Pagina',
      BLOG_POST: 'Blog',
      KNOWLEDGE_ARTICLE: 'FAQ',
    }[page.type] || 'Pagina';

  return (
    <a className="result" href={page.url}>
      <div className="page-type">{pageType}</div>
      <div className="title" dangerouslySetInnerHTML={{ __html: page.title }} />
      <div
        className="excerpt"
        dangerouslySetInnerHTML={{ __html: page.description }}
      />
    </a>
  );
};

const MenuSearchPageHits = ({ p }) => {
  const { results } = usePageSearch();
  if (!results) return <></>;
  const pages = results.results;
  return (
    <>
      <StyledMenuSearchPageHits {...p}>
        {pages?.map((page, ix) => (
          <Hit page={page} key={ix} />
        ))}
        <SearchViewMore
          onClick={() => {
            const reload = queryString.parse(window.location).type === 'adListing';
            window.location.assign(adListing({}));
            reload && window.location.reload();
          }}
        >
          <span>Bekijk onze voorraad</span>
        </SearchViewMore>
      </StyledMenuSearchPageHits>
    </>
  );
};
const Tab = () => {
  const { results } = usePageSearch();
  const total = results?.total || 0;
  return <>Pagina&apos;s ({total})</>;
};
MenuSearchPageHits.Tab = Tab;
export default MenuSearchPageHits;
