import { css } from '@emotion/react';
import styled from '@emotion/styled';

export default styled.div`
  & > .tabs {
    position: relative;
    display: flex;
    gap: 2em;
    border-bottom: solid thin ${({ theme }) => theme.colors.quartz};
    margin-bottom: 20px;

    --active-item-width: 0;
    --active-item-left: 0;
    .tab {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.bahamaBlue};
      cursor: pointer;
      &.active {
        font-weight: 600;
        color: ${({ theme }) => theme.colors.pacificBlue};

        ${({ initiated }) =>
          initiated &&
          css`
            transition: color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
          `}
      }
    }
    &::before {
      content: '';
      position: absolute;
      height: 2px;
      width: 1px;
      bottom: -1px;
      left: 0;
      background: ${({ theme }) => theme.colors.bahamaBlue};
      transform-origin: left;
      transform: translateX(calc(var(--active-item-left) * 1px))
        scaleX(var(--active-item-width));
      ${({ initiated }) =>
        initiated &&
        css`
          transition: transform 0.3s ease-in-out;
        `}
    }
    .page > * {
      width: 100%;
    }
  }
`;
