import React from 'react';
import StyledMobileMenu from './MobileMenu.style';
import MenuBar from './MenuBar';
import MainMenuItem from './MainMenuItem';
import { use100vh } from 'react-div-100vh';

const MobileMenu = ({ menu, logo, className, setIsMenuOpen, children }) => {
  const height = use100vh();
  return (
    <StyledMobileMenu
      className={className}
      style={{ height: height ? `${height}px` : '100vh' }}
    >
      <MenuBar
        isStatic
        onClickMenuButton={() => setIsMenuOpen(false)}
        logo={logo}
        background="transparent"
        icon="close"
      />
      <ul>
        {menu?.children?.map(({ label, children }, ix) => (
          <MainMenuItem key={ix} label={label}>
            {children}
          </MainMenuItem>
        ))}
        {children}
      </ul>
    </StyledMobileMenu>
  );
};

export default MobileMenu;
