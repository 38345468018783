import React from 'react';
import { Configure, Index, useHits } from 'react-instantsearch-hooks-web';
import { indices } from '../app/helpers/indices';
import StyledMenuSearchSuggestions from './MenuSearchSuggestions.style';

const InnerMenuSearchSuggestions = ({ query, setQuery, ...p }) => {
  const { hits } = useHits();
  const mode = query ? 'did-you-mean' : 'popular';
  return !hits.length ? (
    <></>
  ) : (
    <StyledMenuSearchSuggestions className={mode} {...p}>
      <Configure hitsPerPage={5} />
      <div className="title">
        {mode === 'did-you-mean' ? 'Bedoel je' : 'Populaire zoekopdrachten'}
      </div>
      <ul className="suggestions">
        {hits.map((hit, ix) => (
          <li onClick={() => setQuery?.(hit.query)} key={ix}>
            <span
              dangerouslySetInnerHTML={{
                __html: hit._highlightResult?.query?.value || hit.query,
              }}
            />
            {hit.exact_nb_hits && ` (${hit.exact_nb_hits})`}
          </li>
        ))}
      </ul>
    </StyledMenuSearchSuggestions>
  );
};
const MenuSearchSuggestions = (p) => {
  return (
    <Index indexName={indices.vehicles_query_suggestions}>
      <InnerMenuSearchSuggestions {...p} />
    </Index>
  );
};
export default MenuSearchSuggestions;
