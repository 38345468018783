import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import StyledSearchTabs from './SearchTabs.style';
import Slide from './Slide';
import Slides from './Slides';

// eslint-disable-next-line react/display-name
const Tab = React.forwardRef(({ children, className, ...p }, ref) => {
  return (
    <div
      ref={ref}
      {...p}
      className={'tab' + (className ? ` ${className}` : '')}
    >
      {children}
    </div>
  );
});

const SearchTabs = ({ tabs, children, ...p }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [initiated, setInitiated] = useState(false);

  const tabsRef = useRef(null);
  const pagesRef = useRef(null);

  const tabRef = useRef({});
  const pageRef = useRef({});

  const activeTabElem = tabRef.current[activeTab];
  const tabsElem = tabsRef.current;

  useEffect(() => {
    if (!activeTabElem || !tabsElem) return;
    setInitiated(true);
    const update = () => {
      tabsElem.style.setProperty(
        '--active-item-width',
        `${activeTabElem.offsetWidth}`
      );
      tabsElem.style.setProperty(
        '--active-item-left',
        `${activeTabElem.offsetLeft}`
      );
    };
    update();
    const observer = new ResizeObserver(update);
    observer.observe(activeTabElem);
    return () => {
      observer.disconnect();
    };
  }, [activeTabElem, tabsElem]);

  return (
    <StyledSearchTabs initiated={initiated} {...p}>
      <div ref={tabsRef} className="tabs">
        {tabs?.map((InnerTab, i) => (
          <Tab
            key={i}
            ref={(e) => (tabRef.current[i] = e)}
            onClick={() => setActiveTab(i)}
            className={i === activeTab ? 'active' : ''}
          >
            <InnerTab />
          </Tab>
        ))}
      </div>
      <div ref={pagesRef} className="pages">
        <Slides slide={activeTab}>
          {React.Children.map(children, (child, i) => (
            <Slide key={i} className="page">
              {child}
            </Slide>
          ))}
        </Slides>
      </div>
    </StyledSearchTabs>
  );
};
export default SearchTabs;
