const { useEffect, useRef } = require("react");

const useAutoHide = ({
    enabled = false,
    bodyClass = null,
    shouldShow = () => true,
}) => {
    const shouldShowRef = useRef();
    shouldShowRef.current = shouldShow;
    useEffect(() => {
        let scrollPos = null;
        const handler = () => {
            if (bodyClass !== null && scrollPos !== null) {
                if (window.scrollY !== scrollPos) {
                    const val = enabled && !shouldShowRef.current(scrollPos, window.scrollY);
                    if (document.body.classList.contains(bodyClass) !== val) {
                        document.body.classList.toggle(bodyClass, val);
                        document.body.dispatchEvent(new Event('class-changed'));
                    }
                }
            }
            if(window.innerWidth > 376){
                scrollPos = window.scrollY;
            }

        };
        handler();
        if (!enabled) return;
        window.addEventListener('scroll', handler);
        return () => {
            if (bodyClass)
                document.body.classList.remove(bodyClass);

            window.removeEventListener('scroll', handler);
        };
    }, [enabled, bodyClass]);
}
export default useAutoHide