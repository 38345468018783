import React from 'react';
import { Configure, useHits } from 'react-instantsearch-hooks-web';
import { Car, ChevronUp } from '../app/helpers/icons';
import { adListing, parseUrl } from '../app/helpers/url';
import AdCard from './AdCard';
import Icon from './global/Icon';
import StyledMenuSearchHits from './MenuSearchHits.style';
import SearchViewMore from './SearchViewMore';

const MenuSearchHits = ({ query, mobile, ...p }) => {
  const { hits, results, sendEvent } = useHits();
  const total = results?.nbHits ?? 0;

  return !query ? (
    <></>
  ) : (
    <StyledMenuSearchHits {...p}>
      <Configure hitsPerPage={5} />
      <div className="results">
        {hits.map((ad) => (
          <AdCard
            key={ad.objectID}
            searchStyle={mobile ? 'mobile' : 'desktop'}
            ad={ad}
            sendEvent={sendEvent}
          />
        ))}
        <SearchViewMore
          onClick={() => {
            const reload = parseUrl(window.location).type === 'adListing';
            window.location.assign(
              adListing({
                filters: {
                  q: total ? query : undefined,
                },
              })
            );
            reload && window.location.reload();
          }}
        >
          <span>
            {total <= 0
              ? `Bekijk onze voorraad`
              : total === 1
              ? `Bekijk 1 resultaat in onze voorraad`
              : `Bekijk ${total} resultaten in onze voorraad`}
          </span>
        </SearchViewMore>
      </div>
    </StyledMenuSearchHits>
  );
};
const Tab = () => {
  const { results } = useHits();
  const total = results?.nbHits ?? 0;
  return <>Aanbod ({total})</>;
};
MenuSearchHits.Tab = Tab;

export default MenuSearchHits;
