import { useEffect, useRef } from "react"

const useAutoClose = (open, onClose) => {
    const ignoreEvent = useRef();
    const onCloseRef = useRef();
    onCloseRef.current = onClose || (() => { });
    useEffect(() => {
        if (!open) return;
        const handler = (e) => {
            const a = ignoreEvent.current?.nativeEvent || ignoreEvent.current;
            const b = e?.nativeEvent || e;
            if (a === b) return;
            onCloseRef.current?.()
        }
        window.addEventListener("click", handler)
        return () => {
            window.removeEventListener("click", handler)
        }
    }, [open])
    return e => {
        ignoreEvent.current = e;
    }
}
export default useAutoClose