import { useMemo } from "react";
import gsap from "gsap/gsap-core";


const useBrowserTopBarColor = () => {
    return useMemo(() => {
        const element = document.querySelector("meta[name='theme-color']")
        return (color, { tween = true, duration = .5 } = {}) => {
            if (tween) gsap.to(element, { content: color, duration });
            else element.setAttribute("content", color);
        }
    }, []);
}

export default useBrowserTopBarColor