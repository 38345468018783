import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Container from '../../components/global/Container';

export const globalStyle = (theme) => css`
  body {
    --header-menu-offset: 0px;
    --header-menu-height: 60px;
    ${theme.media.laptop} {
      --header-menu-height: 80px;
    }
  }
  body.header-menu-hidden {
    --header-menu-offset: calc(0px - var(--header-menu-height));
  }
  [data-wl-module='Header'] {
    height: var(--header-menu-height);
    background-color: ${theme.colors.pacificBlue};
  }
  .fixed-content {
    transition: transform 300ms ease-in-out;
    transform: translateX(var(--translate-x));
    max-width: 100vw;
  }
  #modules {
    transition: transform 300ms ease-in-out;
  }
  body {
    --translate-x: 0%;
    --overlay-opacity: 0;
  }
  body.menu-open,
  body.filter-menu-open {
    --translate-x: -25%;
    --overlay-opacity: 0.3;
    overflow: hidden;
  }
  body::before {
    content: '';
    position: fixed;
    z-index: 980;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background-color: black;
    opacity: var(--overlay-opacity);
    transition: opacity 300ms linear;
  }
`;
export const menuBarStyle = (theme) => css`
  --layout-extra-pl: 0px;
  --layout-extra-pr: 0px;
  position: absolute;
  height: var(--header-menu-height);
  width: 100%;
  top: var(--top-bar-height);
  z-index: 989;
`;
export default styled(Container)`
  padding: 0;
`;
