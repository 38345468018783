import React, { useMemo } from 'react';
import StyledFixed from './Fixed.style';
import * as ReactDOM from 'react-dom';
import { isPreview } from '../../app/helpers/hsPreview';

const Fixed = ({ flatpack, ...p }) => {
  if (flatpack || isPreview) return <StyledFixed {...p} />;

  return ReactDOM.createPortal(
    <StyledFixed {...p} />,
    document.querySelector('#app')
  );
};
export default Fixed;
