import { css } from '@emotion/react';
import styled from '@emotion/styled';
import IconStyle from './global/Icon.style';
import TextFieldStyle from './global/TextField.style';

export default styled.div`
  transition: transform 350ms ease-in-out;
  transform: translateY(
    calc(var(--header-menu-offset) + var(--top-bar-offset))
  );
  position: absolute;
  height: var(--header-menu-height);
  width: 100%;
  top: var(--top-bar-height);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => theme.layout.container};

  & > .button {
    pointer-events: initial;
    position: relative;
    align-self: center;
    width: 48px;
    height: 48px;
    z-index: 1010;

    & > .content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      min-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      overflow: hidden;

      border-radius: 4px;
      color: ${({ theme }) => theme.colors.bahamaBlue};
      background-color: ${({ theme }) => theme.colors.white};
      transition: background-color 250ms linear, border-color 250ms linear;

      ${({ active, theme }) =>
        active
          ? css`
              border: 1px solid ${theme.colors.bahamaBlue};
            `
          : css`
              cursor: pointer;
              border: 1px solid ${theme.colors.aliceBlue};
              ${theme.media.hover} {
                &:hover {
                  background-color: ${theme.colors.aliceBlue};
                }
              }
            `}

      & > ${IconStyle} {
        align-self: center;
        margin: 0 14px;
      }
      & > .collapsible {
        --active-width: calc(var(--menu-width) - 48px);
        transition: width 250ms ease-in-out;
        width: ${({ active }) => (active ? 'var(--active-width)' : '0')};
        box-sizing: border-box;
        align-self: stretch;
        & > ${TextFieldStyle} {
          width: 100%;
          height: 100%;
          input {
            border: none;
          }
        }
      }
    }
  }
`;
