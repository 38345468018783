
import React, { useContext, useEffect, useState, useCallback } from "react";


const PageSearchContext = React.createContext({})

export const PageSearchProvider = ({ children }) => {
    const [query, setQuery] = useState("");
    const [requestQuery, setRequestQuery] = useState("");
    const [activeQuery, setActiveQuery] = useState("");
    const [results, setResults] = useState(null);

    const refine = useCallback((query) => {
        setQuery(query);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setRequestQuery(query);
        }, 1);
        return () => clearTimeout(timeout);
    }, [query]);



    useEffect(() => {
        if (!requestQuery) {
            setResults(null);
            setActiveQuery("");
            return;
        }
        const baseUrl = "https://api.hubapi.com"
        const endpoint = "/contentsearch/v2/search"
        const param = new URLSearchParams({
            portalId: 19925732,
            term: requestQuery,
            length: "SHORT"
        })
        const url = `${baseUrl}${endpoint}?${param.toString()}`
        const controller = new AbortController();

        fetch(url, { signal: controller.signal }).then(r => r.json()).then(r => {
            setResults(r);
            setActiveQuery(requestQuery);
        }).catch(e => {
            // ignore errors because of aborted requests
        });
        return () => controller.abort();

    }, [requestQuery]);


    return <PageSearchContext.Provider value={{ activeQuery, refine, results }}>{children}</PageSearchContext.Provider>
}

export const usePageSearch = () => useContext(PageSearchContext);