import styled from '@emotion/styled';
import filterObject from '../../app/helpers/filterObject';

export default styled.div`
  position: fixed;

  z-index: ${({ zIndex = 100 }) => zIndex};

  ${({ top, left, bottom, right }) => {
    if (top === undefined && bottom === undefined) top = 0;
    if (left === undefined && right === undefined) left = 0;
    if (top === true) top = 0;
    if (left === true) left = 0;
    if (bottom === true) bottom = 0;
    if (right === true) right = 0;
    return filterObject(
      { top, left, bottom, right },
      ([_, v]) => v !== undefined
    );
  }}

  ${({ fullWidth = true }) => fullWidth && { width: '100%' }};
  ${({ fullHeight = false }) => fullHeight && { height: '100%' }};

  ${({ pointerEvents = true }) => !pointerEvents && { pointerEvents: 'none' }};
`;
