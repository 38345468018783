import StyledMenuSearchLayer from './MenuSearchLayer.style';
import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { Search } from '../app/helpers/icons';
import Fixed from './global/Fixed';
import Icon from './global/Icon';
import MenuSearch from './MenuSearch';

const ClickTrap = styled.div`
  position: absolute;
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: ${({ active }) => (active ? 0.4 : 0)};
  transition: opacity 250ms linear;
`;

const MenuSearchLayer = ({ menuWidth, ...p }) => {
  const [active, setActive] = useState(false);
  const searchFieldTarget = useRef(null);
  const width = Math.max(menuWidth, 500) + 15;
  const [loadMenuSearch, setLoadMenuSearch] = useState(active);
  useEffect(() => {
    if (active) {
      setLoadMenuSearch(true);
    }
  }, [active]);
  return (
    <Fixed zIndex={1100}>
      <ClickTrap active={active} onClick={() => setActive(false)} />
      <StyledMenuSearchLayer
        {...p}
        onClick={() => setActive(true)}
        active={active}
        style={{
          '--menu-width': `${width}px`,
        }}
      >
        <div className="button">
          <div className="content">
            <div className="collapsible" ref={searchFieldTarget}>
              {/* MenuSearch will render a text field here through a portal */}
            </div>
            <Icon {...Search} />
          </div>
        </div>
        {!!loadMenuSearch && (
          // We're only rendering this element when search is first opened
          // because it will start requesting stuff from Algolia once it's rendered.
          <MenuSearch
            open={active}
            focusImmediately
            close={() => setActive(false)}
            searchFieldTarget={searchFieldTarget.current}
          />
        )}
      </StyledMenuSearchLayer>
    </Fixed>
  );
};
export default MenuSearchLayer;
