import React, { useEffect, useMemo, useState } from 'react';
import { useRef } from 'react';
import useForceUpdate from '../app/hooks/useForceUpdate';
import StyledSlides from './Slides.style';

const Slides = ({ slide, children, ...p }) => {
  const ref = useRef();
  const childRefs = useRef([]);
  const heights = useRef({});
  const slideRef = useRef();
  const initRef = useRef();
  const transitionRef = useRef();
  const [init, setInit] = useState(true);
  const [height, setHeight] = useState(0);
  const transition = 250;

  slideRef.current = slide;
  initRef.current = init;
  transitionRef.current = transition;

  useEffect(() => {
    childRefs.current.splice(children?.length);
  }, [children.length]);

  const updateHeight = useMemo(
    () => (ix) => {
      const element = childRefs.current[ix];
      if (!element) return;
      const height = element.offsetHeight + 1;
      const slide = slideRef.current;
      if (heights.current[ix] === height) return;
      heights.current[ix] = height;
      if (ix === slide) setHeight(height);
    },
    [childRefs.current, heights.current]
  );
  const updateHeights = useMemo(
    () => () => {
      childRefs.current.map((e, ix) => updateHeight(ix));
    },
    [updateHeight, childRefs.current]
  );
  useEffect(() => {
    if (!updateHeights) return;
    window.addEventListener('resize', updateHeights);
    const interval = setInterval(updateHeights, 1000);
    updateHeights();
    if (initRef.current) setTimeout(() => setInit(false), 0);
    return () => {
      window.removeEventListener('resize', updateHeights);
      clearInterval(interval);
    };
  }, [updateHeights]);
  useEffect(() => {
    if (!ref.current || initRef.current) return;
    if (heights.current[slide] > 0) setHeight(heights.current[slide]);
    ref.current.classList.add('animating');
    setTimeout(() => {
      ref.current.classList.remove('animating');
    }, transitionRef.current);
  }, [slide]);

  return (
    <StyledSlides
      ref={ref}
      style={{
        '--slide': slide,
        '--slide-height': `${height}px`,
        '--animate': init ? 0 : 1,
        '--transition': `${transition}ms`,
      }}
      {...p}
    >
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          className:
            (index === slide ? 'active ' : '') + (child.props.className || ''),
          ref: (ref) => {
            if (childRefs.current[index] === ref) return;
            childRefs.current[index] = ref;
            updateHeight(index);
          },
        });
      })}
    </StyledSlides>
  );
};
export default Slides;
