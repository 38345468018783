import { css } from '@emotion/react';
import styled from '@emotion/styled';
import IconStyle from './global/Icon.style';
import TextFieldStyle from './global/TextField.style';

export default styled.li`
  position: relative;
  align-self: center;
  width: 48px;
  height: 48px;
`;
