import styled from '@emotion/styled';

export default styled.div`
  --padding-y: 20px;
  --padding-x: 20px;
  &.mobile {
    z-index: 10;
    border-radius: 0 0 4px 4px;
    --padding-x: 15px;
  }
  &.desktop {
    position: absolute;
    top: calc(100% - 11px);
    width: var(--menu-width);
    max-height: calc(
      100vh - var(--header-menu-offset) - var(--header-menu-height) -
        var(--top-bar-offset) - var(--top-bar-height) - 9px
    );
    border-radius: 4px;
  }
  padding: var(--padding-y) var(--padding-x);
  overflow: scroll;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.quartz};
  box-sizing: border-box;
  transition: opacity 350ms ease-in-out;
  opacity: 1;
  pointer-events: initial;
  display: flex;
  flex-direction: column;
  gap: 41px;
  &.closed {
    opacity: 0;
    pointer-events: none;
  }
  & > div {
    position: relative;
    /*&:not(:first-of-type)::before {
      content: '';
      display: block;
      position: absolute;
      top: -21px;
      background: ${({ theme }) => theme.colors.quartz};
      height: 1px;
      left: calc(0px - var(--padding-x));
      right: calc(0px - var(--padding-x));
    }*/
  }
`;
