import styled from '@emotion/styled';
import { rgba } from 'polished';
import IconStyle from './global/Icon.style';

export default styled.div`
  cursor: pointer;
  display: flex;
  height: 80px;
  border: 1px solid ${({ theme }) => theme.colors.quartz};
  background: ${({ theme }) => theme.colors.aliceBlue};
  color: ${({ theme }) => theme.colors.bahamaBlue};
  border-radius: 4px;
  align-items: center;
  padding: 5px 28px;
  ${({ theme }) => theme.typography.header};

  ${IconStyle}.car {
    color: ${({ theme }) => theme.colors.pacificBlue};
    margin-right: 28px;
  }
  ${IconStyle}.chevron {
    margin-left: 1em;
    transition: transform 250ms ease-in-out;
  }
  & > span {
    text-decoration: underline;
    text-decoration-color: transparent;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
    transition: text-decoration-color 250ms ease-in-out;
  }
  ${({ theme }) => theme.media.hover} {
    &:hover {
      ${IconStyle}.chevron {
        transform: translateX(15px);
      }
      & > span {
        text-decoration-color: ${({ theme }) =>
          rgba(theme.colors.bahamaBlue, 0.5)};
      }
    }
  }
`;
