import React, { useMemo } from 'react';
import StyledButton from './Button.style';
import { ArrowLeft, ArrowRight } from '../../app/helpers/icons';
import Icon from './Icon';

// eslint-disable-next-line react/display-name
const Button = React.forwardRef(
  (
    {
      arrow,
      arrowLeft,
      iconButton = false,
      children,
      disabled,
      active,
      className,
      type,
      as = null,
      href = undefined,
      ...p
    },
    ref
  ) => {
    const tag = useMemo(
      () =>
        (as
          ? as
          : type === 'submit' || type === 'button'
          ? 'button'
          : 'a'
        )?.toLowerCase(),
      [as, type]
    );
    return (
      <StyledButton
        className={
          'button' +
          (disabled ? ' disabled' : '') +
          (active ? ' active' : '') +
          (iconButton ? ' icon-btn' : '') +
          (className ? ` ${className}` : '')
        }
        {...p}
        as={tag}
        role={!href && tag === 'a' ? 'button' : undefined}
        href={href}
        type={type}
        ref={ref}
      >
        {!!arrowLeft && <Icon className="arrow-left" {...ArrowLeft} />}
        {children}
        {!!arrow && <Icon className="arrow" {...ArrowRight} />}
      </StyledButton>
    );
  }
);
export default Button;
