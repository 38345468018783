import styled from '@emotion/styled';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > .title {
    ${({ theme }) => theme.typography.heading6};
    color: ${({ theme }) => theme.colors.bahamaBlue};
  }
  & > .results {
    gap: 15px;
    display: flex;
    flex-direction: column;
  }
`;
