import styled from '@emotion/styled';
import SlideStyle from './Slide.style';

export default styled.div`
  --item-width: 100%;
  --item-gap: 15px;
  --slide: 0;
  --animate: 0;
  --transition: 250ms;
  --slide-height: 0px;
  --x-space: 15px;
  --current-td: calc(var(--animate) * var(--transition));
  overflow: hidden;
  white-space: nowrap;
  margin-left: calc(0px - var(--x-space));
  margin-right: calc(0px - var(--x-space));

  & > ${SlideStyle} {
    white-space: normal;
    width: var(--item-width);
    max-width: var(--item-width);
    display: inline-flex;
    vertical-align: top;
    margin-right: var(--item-gap);
    transform: translateX(
      calc(0px - (var(--slide) * (var(--item-gap) + var(--item-width))))
    );
    transition: transform var(--current-td) ease-in-out;

    padding-left: var(--x-space);
    padding-right: var(--x-space);
  }
  &.animating {
    height: var(--slide-height);
    transition: height var(--current-td) ease-in-out;
  }
  &:not(.animating) > ${SlideStyle}:not(.active) {
    height: 0;
    overflow: hidden;
    pointer-events: none;
  }
`;
