import styled from '@emotion/styled';

export default styled.div`
  line-height: 200%;
  margin: calc(0px - var(--padding-y)) calc(0px - var(--padding-x));
  padding: var(--padding-y) var(--padding-x);
  background: ${({ theme }) => theme.colors.aliceBlue};
  & > .title {
    ${({ theme }) => theme.typography.heading6};
    color: ${({ theme }) => theme.colors.bahamaBlue};
  }
  ul.suggestions {
    list-style: none;
    margin: 0;
    padding: 0;
    & > li {
      cursor: pointer;
      mark {
        background: none;
      }
    }
  }
  &.did-you-mean {
    ul.suggestions > li {
      font-weight: 600;
      mark {
        font-weight: normal;
        color: ${({ theme }) => theme.colors.dimGray};
      }
    }
  }
`;
