import React, { useMemo, useRef, useState, useEffect } from 'react';
import StyledMainMenuItem from './MainMenuItem.style';
import SlideUp from './global/SlideUp';
import Icon from './global/Icon';
import useAutoClose from '../app/hooks/useAutoClose';

const NOT_SELECTED = 0;
const SELF_SELECTED = 1;
const CHILD_SELECTED = 2;

const MainMenuItem = ({
  label,
  children,
  variant = 'mobile',
  onClick,
  ...p
}) => {
  const hasChildren = children && children.length > 0;

  const [expanded, setExpanded] = useState(false);
  const subMenuRef = useRef();
  const menuItemRef = useRef();

  const handleSectionClick = () => {
    if (hasChildren) {
      setExpanded(!expanded);
    }
    onClick?.();
  };

  const selectionState = useMemo(() => {
    if (label.active && label.active != '0') return SELF_SELECTED;
    const findTree = (items, predicate) =>
      items?.find((i) => {
        if (predicate(i)) return true;
        return findTree(i.children, predicate);
      }) || false;
    if (findTree(children, ({ label }) => label.active && label.active != '0'))
      return CHILD_SELECTED;
    return NOT_SELECTED;
  }, [
    JSON.stringify(children),
    label.href,
    label.active,
    window.location.href,
  ]);

  const ignore = useAutoClose(
    expanded,
    variant !== 'mobile' && (() => setExpanded(false))
  );

  const T = useMemo(() => (label.href ? 'a' : 'div'), [label.href]);

  useEffect(() => {
    if (expanded && subMenuRef.current && menuItemRef.current) {
      const handler = () => {
        if (!expanded || !subMenuRef.current || !menuItemRef.current) return;
        /** @type {HTMLElement} */
        const subMenu = subMenuRef.current;
        /** @type {HTMLElement} */
        const menuItem = menuItemRef.current;
        const cs = getComputedStyle(menuItem);
        const paddingLeft = parseFloat(cs.paddingLeft);

        if (
          menuItem.getBoundingClientRect().x +
            paddingLeft +
            subMenu.clientWidth <
          document.body.clientWidth
        ) {
          const x = menuItem.clientWidth / 2 - paddingLeft;
          subMenu.style.setProperty('--arrow-position', `${x}px`);
        } else {
          const paddingRight = parseFloat(cs.paddingRight);
          const x =
            subMenu.clientWidth - (menuItem.clientWidth / 2 - paddingRight);
          subMenu.style.setProperty('--arrow-position', `${x}px`);
        }
      };
      handler();
      window.addEventListener('resize', handler);
      const interval = setInterval(handler, 1000);
      return () => {
        window.removeEventListener('resize', handler);
        clearInterval(interval);
      };
    }
  }, [expanded, subMenuRef.current, menuItemRef.current]);

  return (
    <StyledMainMenuItem className={variant} {...p}>
      <div
        className={`menu-item${expanded ? ' open' : ''}${
          hasChildren ? ' parent-item' : ''
        }${selectionState ? ' active' : ''}`}
        onClick={handleSectionClick}
        ref={menuItemRef}
      >
        {!!selectionState && variant !== 'desktop-sub-menu' && (
          <Icon
            className={
              'arrow-icon' +
              (selectionState === CHILD_SELECTED ? ' child-selected' : '')
            }
            {...(variant === 'mobile'
              ? {
                  width: '8',
                  height: '16',
                  d: 'M 8 8 L 0 16 L 0 0 L 8 8 Z',
                }
              : {
                  width: '16',
                  height: '8',
                  d: 'M 8 0 L 0 8 L 16 8 L 8 0 Z',
                })}
          />
        )}
        <T className="label" href={label.href || undefined}>
          {label.content}
        </T>
        {label.icon
          ? label.icon
          : hasChildren &&
            variant === 'mobile' && (
              <Icon
                className="expand-icon"
                width="8"
                height="14"
                d="M5.17205 6.99999L0.222046 2.04999L1.63605 0.635986L8.00005 6.99999L1.63605 13.364L0.222046 11.95L5.17205 6.99999Z"
              />
            )}
      </div>

      {hasChildren && (
        <SlideUp
          absolute={variant !== 'mobile'}
          open={expanded}
          outerRef={subMenuRef}
          className="header-sub-items"
        >
          <ul className="sub-menu" onClick={(e) => ignore(e)}>
            {children?.map(({ label, children }, ix) => (
              <MainMenuItem
                key={ix}
                label={label}
                variant={variant === 'desktop' ? 'desktop-sub-menu' : variant}
              >
                {children}
              </MainMenuItem>
            ))}
          </ul>
        </SlideUp>
      )}
    </StyledMainMenuItem>
  );
};

export default MainMenuItem;
