import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { Search } from '../app/helpers/icons';
import { useBreakpoint } from '../app/providers/breakpointProvider';
import Fixed from './global/Fixed';
import Icon from './global/Icon';
import MainMenuItem from './MainMenuItem';
import MenuSearch from './MenuSearch';
import StyledMenuSearchMobile from './MenuSearchMobile.style';

const Clicktrap = styled.div`
  position: absolute;
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: ${({ active }) => (active ? 0.4 : 0)};
  transition: opacity 250ms linear;
`;

const InnerMenuSearchMobile = ({ open, close, ...p }) => {
  const height = use100vh();
  const searchFieldTarget = useRef(null);
  const [mounting, setMounting] = useState(true);
  useEffect(() => {
    setTimeout(() => setMounting(false), 0);
  }, []);
  const active = open && !mounting;
  return (
    <Fixed zIndex={1020}>
      <StyledMenuSearchMobile
        {...p}
        style={{ height: height ? `${height}px` : '100vh' }}
      >
        <Clicktrap onClick={() => close()} active={active}></Clicktrap>
        <div
          className={'input' + (active ? ' open' : '')}
          ref={searchFieldTarget}
        ></div>
        <MenuSearch
          mobile
          open={active}
          close={close}
          focusImmediately
          searchFieldTarget={searchFieldTarget.current}
        />
      </StyledMenuSearchMobile>
    </Fixed>
  );
};

const MenuSearchMobile = ({ onClose, ...p }) => {
  const [active, setActive] = useState(false);
  const mounting = useRef(true);
  useEffect(() => {
    if (mounting.current) {
      mounting.current = false;
      return;
    }
    if (!active) {
      onClose?.();
    }
  }, [active]);

  const [loadMenuSearch, setLoadMenuSearch] = useState(false);
  useEffect(() => {
    if (active) {
      setLoadMenuSearch(true);
    } else {
      const timeout = setTimeout(() => {
        setLoadMenuSearch(false);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [active]);

  const isLaptop = useBreakpoint().equalsOrUp('laptop');
  useEffect(() => {
    if (isLaptop && active) {
      setActive(false);
    }
  }, [isLaptop, active]);

  return (
    <>
      <MainMenuItem
        style={{ pointerEvents: 'none', cursor: 'inherit' }}
        label={{
          active: false,
          href: false,
          content: '\xa0',
        }}
      ></MainMenuItem>
      <MainMenuItem
        onClick={() => setActive(true)}
        label={{
          active: false,
          href: false,
          content: 'Zoeken',
          icon: <Icon {...Search} />,
        }}
      ></MainMenuItem>
      {loadMenuSearch && (
        <InnerMenuSearchMobile
          open={active}
          close={() => setActive(false)}
          {...p}
        />
      )}
    </>
  );
};
export default MenuSearchMobile;
