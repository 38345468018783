import React, { useCallback, useEffect, useState } from 'react';
import TextField from './global/TextField';
import * as ReactDOM from 'react-dom';
import { SearchProvider, useSearch } from '../app/providers/searchProvider';
import MenuSearchHits from './MenuSearchHits';
import MenuSearchSuggestions from './MenuSearchSuggestions';
import MenuSearchStyle from './MenuSearch.style';
import ErrorBoundary from './global/ErrorBoundary';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { routing } from '../app/providers/algoliaRouting';
import SearchTabs from './SearchTabs';
import {
  PageSearchProvider,
  usePageSearch,
} from '../app/providers/pageSearchProvider';
import MenuSearchPageHits from './MenuSearchPageHits';

const InnerMenuSearch = ({
  open,
  focusImmediately,
  searchFieldTarget,
  mobile,
  close,
  ...p
}) => {
  const { query: activeQuery, refine } = useSearch();
  const { refine: refinePageQuery, activeQuery: activePageQuery } =
    usePageSearch();

  const [query, setQuery] = useState(activeQuery);
  const menuSearchRef = React.useRef(null);

  useEffect(() => {
    setQuery(activeQuery || '');
  }, [activeQuery]);

  const searchFieldRef = React.useRef(null);
  const [shouldFocus, setShouldFocus] = useState(focusImmediately);
  const [mounting, setMounting] = useState(true);

  useEffect(() => {
    if (open) setShouldFocus(focusImmediately);
  }, [open, focusImmediately]);
  useEffect(() => {
    const target = menuSearchRef.current;
    if (open && target) {
      disableBodyScroll(target);
      return () => {
        enableBodyScroll(target);
      };
    }
  }, [open, menuSearchRef.current]);
  useEffect(() => {
    if (!shouldFocus) return;
    searchFieldRef.current?.focus();
    setShouldFocus(false);
  }, [searchFieldRef.current, shouldFocus]);
  useEffect(() => {
    setTimeout(() => {
      setMounting(false);
    }, 500);
  }, []);

  const refineQuery = useCallback((value) => {
    setQuery(value);
    refine(value);
    refinePageQuery(value);
  }, []);

  return (
    <>
      {!!searchFieldTarget &&
        ReactDOM.createPortal(
          <TextField
            ref={searchFieldRef}
            placeholder="Zoeken naar..."
            value={query}
            onChange={refineQuery}
            clearable
            alwaysShowClear={mobile}
            onClear={(before) => {
              if (!before) close?.();
            }}
          />,
          searchFieldTarget
        )}
      <MenuSearchStyle
        ref={menuSearchRef}
        className={
          (mounting || !open ? 'closed' : '') +
          (mobile ? ' mobile' : ' desktop')
        }
      >
        <MenuSearchSuggestions query={activeQuery} setQuery={refineQuery} />
        {activeQuery && (
          <SearchTabs tabs={[MenuSearchHits.Tab, MenuSearchPageHits.Tab]}>
            <MenuSearchHits query={activeQuery} mobile={mobile} {...p} />
            <MenuSearchPageHits
              query={activePageQuery}
              mobile={mobile}
              {...p}
            />
          </SearchTabs>
        )}
      </MenuSearchStyle>
    </>
  );
};
const MenuSearch = ({ open, ...p }) => {
  useEffect(() => {
    if (open) {
      routing.disable();
      return () => {
        routing.enable();
      };
    }
  }, [open]);
  return (
    <ErrorBoundary>
      <SearchProvider>
        <PageSearchProvider>
          <InnerMenuSearch open={open} {...p} />
        </PageSearchProvider>
      </SearchProvider>
    </ErrorBoundary>
  );
};

export default MenuSearch;
