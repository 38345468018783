import React, { useEffect, useRef, useState } from 'react';
import StyledMenuBar from './MenuBar.style';
import MainMenuItem from './MainMenuItem';
import Icon from './global/Icon';
import Picture from './global/Picture';
import MenuSearchBar from './MenuSearchBar';
import { isPreview } from '../app/helpers/hsPreview';

const MenuBar = ({
  logo,
  background = 'white',
  icon = 'hamburger',
  showButton = true,
  showMenu = false,
  showSearch = true,
  menu,
  onClickMenuButton,
  ...p
}) => {
  const menuRef = useRef();
  const [menuWidth, setMenuWidth] = useState(null);

  useEffect(() => {
    if (!menuRef.current) return;
    const ref = menuRef.current;
    const updateWidth = () => {
      if (ref.offsetWidth) setMenuWidth(ref.offsetWidth);
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    const interval = setInterval(updateWidth, 500);
    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', updateWidth);
    };
  }, [menuRef.current]);

  return (
    <StyledMenuBar background={background} {...p}>
      <div className="bar">
        <a href="/" className="logo">
          <Picture
            images={{ 1: logo.src }}
            width={139}
            height={28}
            alt="Autokan logo"
          />
        </a>
        {showButton && (
          <Icon
            width="22"
            height="22"
            className="menu-btn"
            onClick={() => onClickMenuButton()}
            d={
              icon === 'hamburger'
                ? 'M 22 6 H 0 V 8 H 22 Z M 22 14 H 0 V 16 H 22 Z'
                : 'M 19 4.4 L 17.6 3 L 11 9.6 L 4.4 3 L 3 4.4 L 9.6 11 L 3 17.6 L 4.4 19 L 11 12.4 L 17.6 19 L 19 17.6 L 12.4 11 L 19 4.4 Z'
            }
          />
        )}
        {showMenu && (
          <ul ref={menuRef} className="desktop-menu">
            {menu?.children?.map(({ label, children }, ix) => (
              <MainMenuItem key={ix} label={label} variant="desktop">
                {children}
              </MainMenuItem>
            ))}
            {!isPreview && showSearch && (
              <MenuSearchBar menuWidth={menuWidth} />
            )}
          </ul>
        )}
      </div>
    </StyledMenuBar>
  );
};

export default MenuBar;
