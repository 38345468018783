import React, { useEffect, useRef, useState } from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import MenuBar from '../../components/MenuBar';
import MobileMenu from '../../components/MobileMenu';
import { globalStyle, menuBarStyle } from './module.style';
import { css, Global, useTheme } from '@emotion/react';
import Fixed from '../../components/global/Fixed';
import useBrowserTopBarColor from '../../app/hooks/useBrowserTopBarColor';
import useAutoHide from '../../app/hooks/useAutoHide';
import { useBreakpoint } from '../../app/providers/breakpointProvider';
import MenuSearchMobile from '../../components/MenuSearchMobile';
import isEmbedded from '../../app/helpers/isEmbedded';

export const getServerSideProps = async () => {
  return {
    props: {},
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const HeaderModule = ({
  logo,
  menu,
  flatpack,
  showSearch: _showSearch,
  ...p
}) => {
  const showSearch = _showSearch?.content !== 'false';

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const showHeader = !isEmbedded();

  const theme = useTheme();
  const setTopBarColor = useBrowserTopBarColor();

  useEffect(() => {
    const handler = () => {
      let color = theme.colors.whiteSmoke;
      if (document.body.classList.contains('menu-open')) {
        color = theme.colors.aliceBlue;
      } else if (document.body.classList.contains('filter-menu-open')) {
        color = theme.colors.aliceBlue;
      } else if (document.body.classList.contains('top-bar-hidden')) {
        color = theme.colors.white;
      }
      setTopBarColor(color);
    };
    document.body.addEventListener('class-changed', handler);
    return () => {
      document.body.removeEventListener('class-changed', handler);
    };
  }, [theme]);

  useEffect(() => {
    document.body.classList.toggle('menu-open', isMenuOpen);
    document.body.dispatchEvent(new Event('class-changed'));
  }, [isMenuOpen]);

  useAutoHide({
    enabled: false,
    bodyClass: 'header-menu-hidden',
    shouldShow: (oldY, newY) => oldY <= 60 || oldY > newY,
  });

  const hamburgerMenu = useBreakpoint().equalsOrDown('tablet');

  const hasMenu = !!menu;

  useEffect(() => {
    if ((!hasMenu || !hamburgerMenu) && isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [hasMenu, hamburgerMenu, isMenuOpen]);

  return (
    <>
      {showHeader && (
        <>
          <Global styles={globalStyle} />
          <Fixed className="fixed-content" zIndex={950} flatpack={flatpack}>
            <MenuBar
              css={menuBarStyle}
              logo={logo}
              isMenuOpen={isMenuOpen}
              showMenu={hasMenu && !hamburgerMenu}
              showButton={hasMenu && hamburgerMenu}
              showSearch={showSearch}
              menu={menu}
              onClickMenuButton={() => setIsMenuOpen(true)}
            ></MenuBar>
          </Fixed>
          <Fixed zIndex={990} flatpack={flatpack}>
            <MobileMenu
              logo={logo}
              setIsMenuOpen={setIsMenuOpen}
              menu={menu}
              showSearch={showSearch}
            >
              {showSearch && (
                <MenuSearchMobile
                  onClose={() => {
                    //setIsMenuOpen(false);
                  }}
                />
              )}
            </MobileMenu>
          </Fixed>
        </>
      )}
    </>
  );
};

export default HeaderModule;

registerModule({ Header: Self });
