import styled from '@emotion/styled';

export default styled.li`
  position: relative;
  list-style: none;
  white-space: nowrap;

  &.mobile {
    .menu-item {
 
      border-bottom: solid thin ${({ theme }) => theme.colors.quartz};
      .arrow-icon {
        left: 0;
        transition: transform 150ms ease-in-out;
      }
      &.open {
        .arrow-icon.child-selected {
          transform: translateX(-10px);
        }
      }

      .label {
        padding:15px;
        width:100%;
      }
      svg {
        margin-right: 15px;
      }
    }
    .header-sub-items {
      .menu-item {
        padding-left: 30px;
      }
    }
  }

  &.desktop {
    & > .menu-item {
      height: 100%;
    }
  }
  &.desktop,
  &.desktop-sub-menu {
    & > .menu-item {
      color: ${({ theme }) => theme.colors.bahamaBlue};
      &.active {
        color: ${({ theme }) => theme.colors.pacificBlue};
      }
      .arrow-icon {
        bottom: -1px;
        transition: transform 150ms ease-in-out;
        left: 50%;
        margin-left: -8px;
      }
    }
    .header-sub-items {
      --arrow-position: 60px;
      --arrow-x: calc(0px + var(--arrow-position));
      left: calc(50% - var(--arrow-x));
      top: 100%;
      margin-top: -20px;

      .sub-menu {
        margin: 20px 0 0;
        padding: 20px;
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 4px;
        border: solid thin ${({ theme }) => theme.colors.quartz};
        display: flex;
        flex-direction: column;
        gap: 15px;
        &::before {
          content: '';
          position: absolute;
          display: block;
          background: ${({ theme }) => theme.colors.white};
          width: ${Math.sqrt(Math.pow(8, 2) * 2)}px;
          height: ${Math.sqrt(Math.pow(8, 2) * 2)}px;
          border: solid thin ${({ theme }) => theme.colors.white};
          border-left-color: ${({ theme }) => theme.colors.quartz};
          border-top-color: ${({ theme }) => theme.colors.quartz};
          top: -8px;
          left: var(--arrow-x);
          transform: rotate(45deg);
          transform-origin: top left;
        }
      }
      .menu-item {
        ${({ theme }) => theme.typography.controlStrong};
      }
    }
  }

  .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => theme.typography.header};

    .arrow-icon {
      position: absolute;
      color: ${({ theme }) => theme.colors.pacificBlue};
    }

    .label {
      cursor: pointer;
      transition: color 0.3s;

      ${({ theme }) => theme.media.hover} {
        &:hover {
          color: ${({ theme }) => theme.colors.pacificBlue};
          text-decoration: underline;
        }
      }
    }

    svg.expand-icon {
      transition: transform 0.3s;

      path {
        transition: fill 0.3s;
      }
    }

    ${({ theme }) => theme.media.hover} {
      &:hover {
        svg.expand-icon > path {
          fill: ${({ theme }) => theme.colors.pacificBlue};
        }
      }
    }

    &.open {
      a {
        color: ${({ theme }) => theme.colors.pacificBlue};
      }
      svg.expand-icon {
        transform: rotate(90deg);

        path {
          fill: ${({ theme }) => theme.colors.pacificBlue};
        }
      }
    }
  }

  div.parent-item {
    cursor: pointer;

    ${({ theme }) => theme.media.hover} {
      &:hover {
        a {
          color: ${({ theme }) => theme.colors.pacificBlue};
        }
      }
    }
  }

  .header-sub-items {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .menu-item {
      ${({ theme }) => theme.typography.controlStrong};
    }
  }
`;
